.phone {
  --black5: #8a8a8d;
  --black4: #959698;

  z-index: 1;
  height: 100%;
  min-height: 40rem;
  padding: 0 0.5rem;
  border: 0.15rem solid var(--black5);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #eaeaea;
  transition: all 0.3s;
  margin:auto;
  max-width: 30rem;
  min-width: 25rem;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.phone_mic {
  height: 0.25rem;
  width: 4rem;
  margin: 1rem auto;
  border-radius: 999rem;
  background-color: var(--black4);
}
.phone_screen {
  position: relative;
  flex: 1 0 auto;
  padding:20px;
  border: 0.15rem solid var(--black5);
  background-color: #ffffff;
}
.phone_screen::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
}
.phone_button {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--black4);
  border-radius: 50%;
  margin: 1rem auto;
}
